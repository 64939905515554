<template>
	<div class="pa-3 new-back full-height">

		<filter-search-input
			v-model="filters.search" :applied-filters="appliedFilters"
			@input="searchChanged"
			outlined dense
		>
			<template #append>
				<div>
					<excel-btn @click="downloadExcel"/>
					<v-tooltip v-if="permissions.add" top z-index="200">
						<template #activator="{ on }">
							<v-btn v-on="on" @click="$router.push('invoice/new')" fab color="primary" class="float-right" small>
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</template>
						<span class="caption">Add new</span>
					</v-tooltip>
				</div>
			</template>

			<v-autocomplete
				v-model="filters.customers" :items="customers"
				@change="fetchInvoices"
				label="Customer"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="id"
			/>

			<v-select
				v-model="filters.customerCountries" :items="countries"
				@change="fetchInvoices"
				label="Country"
				dense outlined hide-details clearable multiple
				item-text="name" item-value="value"
			/>

			<q-date-picker
				v-model="filters.startDate" :max="filters.endDate"
				@input="fetchInvoices"
				label="Start Date"
				inner outlined dense hide-details clearable
			/>

			<q-date-picker
				v-model="filters.endDate" :min="filters.startDate"
				@input="fetchInvoices"
				label="End Date"
				inner outlined dense hide-details clearable
			/>

			<v-select
				v-model="filters.currencies" :items="currencies"
				@change="fetchInvoices"
				label="Currency"
				dense outlined hide-details clearable input-select multiple
				item-text="text" item-value="text"
			/>
			
			<v-select
				v-model="filters.statuses" :items="statuses"
				@change="fetchInvoices"
				label="Status"
				dense outlined hide-details clearable input-select multiple hide-input
			/>
		</filter-search-input>

		<invoices-table
			class="rounded-lg bordered new-back lighten2"
			:item-class="() => permissions.detail ? 'cursor-pointer' : ''"
			:items="invoices"
			:show-actions="permissions.actions"
			:pagination.sync="pagination"
			@click:row="item => permissions.detail ? $router.push('/admin/invoice/' + item.id) : null"
			@updated="fetchInvoices"
			@pagination="fetchInvoices"
		/>
	</div>
</template>

<script>
import moment from "moment"
import InvoicesTable from "../../components/InvoicesTable"
import ExcelBtn from "../../components/ExcelBtn.vue"

export default {
	props: ['customerId', 'paid', 'currency'],
	components: { InvoicesTable, ExcelBtn },
	data: () => ({
		customers: [],
		invoices: [],
		filters: {
			customers: [],
			currencies: [],
			statuses: [],
		},
		currencies: [
			{text: 'MXN'},
			{text: 'USD'},
			{text: 'EUR'},
		],
		countries: [
			{ name: 'Mexico', value: 0 },
			{ name: 'USA', 		value: 1 },
		],
		statuses: [
			{ text: 'Pending', value: 1 },
			{ text: 'Paid', value: 2 },
			{ text: 'Cancelled', value: 3 },
			{ text: 'Partial', value: 4 },
			{ text: 'Draft', value: 0 },
		],

		pagination: {
			page: 1,
			itemsPerPage: 10,
			totalItems: 0,
			sortBy: '',
			sortDesc: false,
		},
		searchTimeout: null,

		permissions: {
			detail: false,
			add: false,
			actions: false,
		},
	}),
	computed: {
		appliedFilters(){
			return this.countActiveFilters(this.filters, ['search'])
		},
	},
	mounted() {
		document.title = 'Invoices'

		this.$security.hasRequiredPermission('detail')
			.then(res => this.permissions.detail = res)
		this.$security.hasRequiredPermission('add')
			.then(res => this.permissions.add = res)
		this.$security.hasRequiredPermission('full_access')
			.then(res => this.permissions.actions = res)

    this.$q.log(4, 26, 3, 'NA')
		this.setUserPreferences("Invoices", "filters")
		if(this.customerId){
			this.filters = {
				customers: [],
				currencies: [],
				statuses: [1, 2, 4],
			}
		  this.filters.customers.push(this.customerId)

			if(this.paid == 'false'){
				this.filters.statuses = [1, 4]
			}
			if(this.currencies){
				this.filters.currencies.push(this.currency)
			}
		}

		this.init()
	},
	methods: {
		init() {
			this.$http.get("/api/customer?onlyWithCustomerProjects=true")
				.then(res => {
					this.customers = _.orderBy(res.data, ['name'])
				})
				.catch(err => {
					console.error(err)
					this.$root.error("Cannot obtain customers.")
				})
		},
		fetchInvoices() {
			let sortBy = Array.isArray(this.pagination.sortBy) ? this.pagination.sortBy[0] : this.pagination.sortBy
			let sortDesc = Array.isArray(this.pagination.sortDesc) ? this.pagination.sortDesc[0] : this.pagination.sortDesc

			this.$http.post(
				'api/invoice/list',
				{
					search: this.filters.search,
					statuses: this.filters.statuses,
					customers: this.filters.customers,
					customerCountries: this.filters.customerCountries,
					currencies: this.filters.currencies,
					startDate: this.filters.startDate,
					endDate: this.filters.endDate,

					page: this.pagination.page,
					pageSize: this.pagination.itemsPerPage,
					sortBy: sortBy,
					sortDesc: sortDesc,
				}
			)
				.then(res => {
					this.invoices = res.data.invoices
					this.pagination.totalItems = res.data.totalItems

					if(this.invoices.length == 0 && this.pagination.totalItems){
						this.pagination.page = 1
					}
				})
				.catch(err => {
					console.error(err)
					this.$root.error("Cannot obtain invoices.")
				})
		},
		searchChanged() {
			clearTimeout(this.searchTimeout)
			this.searchTimeout = setTimeout(this.fetchInvoices, 500)
		},
		async downloadExcel() {
			let sortBy = Array.isArray(this.pagination.sortBy) ? this.pagination.sortBy[0] : this.pagination.sortBy
			let sortDesc = Array.isArray(this.pagination.sortDesc) ? this.pagination.sortDesc[0] : this.pagination.sortDesc

			let invoices = []
			try {
				let res = await this.$http.post(
					'api/invoice/list',
					{
						search: this.filters.search,
						statuses: this.filters.statuses,
						customers: this.filters.customers,
						customerCountries: this.filters.customerCountries,
						currencies: this.filters.currencies,
						startDate: this.filters.startDate,
						endDate: this.filters.endDate,

						page: 1,
						pageSize: -1,
						sortBy: sortBy,
						sortDesc: sortDesc,
					}
				)
				invoices = res.data.invoices
			} catch (error) {
				console.error(error)
				this.$root.error("Cannot obtain invoices.")
				return
			}

			const excelHeaders = [
				{ name: 'Invoice No.', value: x => x.code },
				{ name: 'Customer', value: x => x.customerName },
				{ name: 'Customer Legal Name', value: x => x.rfcName },
				{ name: 'Country', value: x => this.countries.find(y => y.value == x.customerCountry)?.name || '' },
				{ name: 'Invoice Date', value: x => moment(x.issueDate).format('DD/MM/YYYY') },
				{ name: 'Delivery Date', value: x => moment(x.deliveryDate).format('DD/MM/YYYY') },
				{ name: 'Due Date', value: x => moment(x.paymentDay).format('DD/MM/YYYY') },
				{ name: 'Payment Date', value: x => x.lastPaymentDate ? moment(x.lastPaymentDate).format('DD/MM/YYYY') : '' },
				{ name: 'Projects', value: x => x.projects.map(p => p.name).join(',') },
				{ name: 'Currency', value: x => x.currency },
				{ name: 'Status', value: x => x.statusDisplayName },
				{ name: 'Subtotal', value: x => x.subtotal },
				{ name: 'Total', value: x => x.total },
				{ name: 'Billed', value: x => x.billed },
			]

			this.singleSheetExcel(invoices, excelHeaders, 'Invoices')
		},
	},
}
</script>

<style lang="scss" scoped>
</style>