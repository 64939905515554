var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.shownHeaders,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"page":_vm.pagination.page,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.sortDesc,"server-items-length":_vm.pagination.totalItems},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.pagination, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},function($event){return _vm.$emit('pagination', $event)}],"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.pagination, "sortDesc", $event)},function($event){return _vm.$emit('pagination', $event)}]},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.code || item.invoiceCode))])]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('b',[_vm._v(_vm._s(item.customerName))]),_c('br'),_vm._v(" "+_vm._s(item.rfcName)+" ")])]}},{key:"item.customerCountry",fn:function(ref){
var item = ref.item;
return [(item.customerCountry !== undefined)?_c('div',{staticClass:"d-flex align-center",attrs:{"set":_vm.country = _vm.countries.find(function (x) { return x.value === item.customerCountry; })}},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":require(("../assets/currencies/" + (_vm.country.icon) + ".png"))}})],1),_c('h3',[_vm._v(_vm._s(_vm.country.name))])],1):_vm._e()]}},{key:"item.issueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.issueDate || item.invoiceIssueDate)))+" ")]}},{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.deliveryDate || item.invoiceDeliveryDate)))+" ")]}},{key:"item.paymentDay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.paymentDay || item.invoicePaymentDay)))+" ")]}},{key:"item.lastPaymentDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastPaymentDate))+" ")]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"24"}},[_c('v-img',{attrs:{"src":require(("../assets/currencies/" + ((item.currency || item.invoiceCurrency).toLowerCase()) + ".png"))}})],1),_c('b',[_vm._v(" "+_vm._s((item.currency || item.invoiceCurrency))+" ")])],1)]}},{key:"item.projects",fn:function(ref){
var item = ref.item;
return _vm._l(((item.projects || item.invoiceProjects)),function(project,ix){return _c('div',{key:ix,staticClass:"project",style:({background: _vm.customerProjectTypes[project.type].lightColor})},[_c('div',{staticClass:"letter",style:({background: _vm.customerProjectTypes[project.type].color})},[_vm._v(" "+_vm._s(_vm.customerProjectTypes[project.type].small)+" ")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(project.name))])])})}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.subtotal || item.invoiceSubtotal))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.total || item.invoiceTotal))+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'error--text': Math.abs((item.billed || item.invoiceBilled || 0) - (item.subtotal || item.invoiceSubtotal || 0)) >= 0.01 }},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.billed || item.invoiceBilled))+" ")])]}},{key:"item.paidAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.paidAmount || item.invoicePaidAmount))+" ")]}},{key:"item.pendingAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.pendingAmount || item.invoicePendingAmount))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"border-radius":"20px"},style:({
				background: _vm.$vuetify.theme.isDark ? '' : (item.statusLightColor || item.invoiceStatusLightColor)
			}),attrs:{"color":(item.statusColor || item.invoiceStatusColor),"outlined":"","small":""}},[_vm._v(_vm._s(item.statusName || item.invoiceStatusName))])]}},{key:"item.actions",fn:function(ref){
			var item = ref.item;
return [_c('v-menu',{attrs:{"left":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-1",attrs:{"dense":""}},[_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"grey"},on:{"click":function($event){return _vm.$router.push(("invoice/new?copyFrom=" + (item.id)))}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Duplicate")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"#ff6347","text":"","small":""},on:{"click":function($event){return _vm.$adminSurf('invoiceToPdf', item)}}},on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Export to PDF")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$adminSurf('openHistory', item, 'invoice')}}},on),[_c('v-icon',[_vm._v("mdi-history")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("History")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('v-tooltip',{attrs:{"right":"","z-index":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){return _vm.cancelInvoice(item)}}},on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',{staticClass:"caption"},[_vm._v("Cancel")])])],1),_c('v-list-item',{staticClass:"pa-1 py-0"},[_c('watchers-menu',{attrs:{"entity-id":item.id,"watchers":item.subscribers,"required-permission":"invoices"},on:{"watcher-added":function($event){return item.subscribers.push($event)},"watcher-removed":function($event){item.subscribers.splice(item.subscribers.findIndex(function (x) { return x.userId == $event; }), 1)}}})],1)],1)],1)]}},{key:"item.amount",fn:function(props){return [_vm._t("item.amount",null,null,props)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }